.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}
