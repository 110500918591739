/** Account Styles **/
.sign-in-page {
  #sign-in-paypal-panel {
    .column {
      width: 50%;
      float: left;
      input {
        width: 100%;
      }
      p {
        min-height: 1.5rem;
      }
      #forgot-pw-note {
        display: inline-block;
        margin-bottom: 1rem;
      }
    }
  }
  @include breakpoint($medium-up) {
    .sign-in__section {
      width: 50%;
      margin: 0 auto;
    }
  }
  @include breakpoint($small-down) {
    .form-item.submit {
      float: none;
      width: 100%;
      padding: 0;
      .button {
        margin: 0;
        max-width: 100%;
      }
    }
    fieldset .form-item {
      float: none;
      padding-right: 0;
    }
  }
  .new-account {
    .form-item.privacy-policy {
      .label-content {
        a {
          color: inherit;
          border-color: inherit;
        }
      }
    }
    .password-field {
      overflow: visible;
      &__form-item {
        position: relative;
        overflow: visible;
      }
    }
  }
}

.page-header {
  .address-book-page &,
  .payment-info-page & {
    margin-bottom: 2em;
  }
}

.account-section {
  padding-top: 0;
  .profile-info,
  .optional-info,
  .privacy-policy,
  .pro-member-info,
  .newsletter-info,
  .sms-promotions,
  .sms-info {
    &__item {
      select {
        width: 100%;
      }
    }
  }
  .profile-page__content {
    padding-bottom: 2em;
    h2 {
      margin: 2em auto 3em;
    }
  }
  .sms-info__item--mobile-phone {
    overflow: hidden;
    .form-field__prefix {
      float: left;
      width: 15%;
      padding-top: 0.8em;
    }
    .form-field__input {
      float: right;
      width: 85%;
      margin-top: 6px;
    }
  }
  #customer-service {
    display: none;
  }
  .profile-pic-main {
    display: none;
  }
  .past-purchases__list {
    padding: 2rem;
    position: relative;
    .view-details-button {
      position: absolute;
      right: 2rem;
      top: 2.5rem;
      .icon--caret--down {
        margin-top: 0.5rem;
      }
    }
  }
  .past-purchases__item--details {
    .close {
      text-align: right;
    }
    .add-all-to-bag {
      margin-bottom: 1.5rem;
    }
    .product-footer {
      .total,
      .total-quantity,
      .price {
        display: inline-block;
      }
    }
  }
  .order-info.accordion-content {
    padding: 2rem;
  }
  .address-book__link,
  .payment-info__link,
  .order-info__link {
    text-decoration: underline;
  }
  .section-head__link {
    float: right;
    text-decoration: none;
  }
  input[type='text'] {
    width: 100%;
  }
  a.selectBox,
  .selectBox {
    width: 100%;
    &.error {
      border-color: #cd3030;
    }
  }
  .cart-item .cart-item__price {
    font-size: 0.75rem;
  }
  .order-products {
    .cart-item__desc,
    .cart-item__thumb {
      display: inline-block;
      width: 49%;
      .cart-item__qty {
        text-align: left;
      }
      .cart-item__qty-label {
        display: inline-block;
      }
    }
  }
  .address-book-page__content {
    @include breakpoint($medium-up) {
      float: right;
    }
    .address-book,
    .payment-info {
      .address-item,
      .payment-item {
        padding: 0;
        background: transparent;
        .address-item__controls,
        .payment-item__controls {
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  .optional-info__item {
    @include breakpoint($medium-up) {
      a.selectBox,
      .selectBox {
        width: auto;
      }
      &.optional-info__item--birth-day {
        clear: left;
        width: 26%;
      }
      &.optional-info__item--birth-month {
        width: 26%;
      }
      &.optional-info__item--birth-year {
        width: 30%;
      }
      a,
      select {
        &.selectBox {
          width: 100%;
        }
      }
    }
  }
  .expiration_date_container {
    a.selectBox,
    .selectBox {
      width: 48%;
    }
  }
  .form_element {
    margin-top: 1rem;
    &.default_payment_container {
      margin-bottom: 1rem;
    }
  }
  .heading--5 {
    line-height: 1.75;
  }
  @include breakpoint($small-down) {
    #customer-service,
    .account-page__content {
      padding: 0 1.5rem;
      .section-content {
        padding: 1em 0;
        a {
          text-decoration: underline;
        }
      }
      section {
        margin: 1rem 0;
      }
    }
    .heading--5 {
      font-size: 1.12rem;
      width: 75%;
      text-align: left;
      line-height: 1.5;
      padding: 0;
    }
    .heading--8 {
      padding-bottom: 0.5rem;
    }
    .section-header {
      position: relative;
      .section-head__link {
        float: right;
      }
    }
    .optional-info__item {
      .selectBox {
        min-width: 32%;
      }
    }
  }
  .account-page {
    &__content {
      .section-content {
        a {
          text-decoration: none;
        }
      }
    }
  }
}

.account--signin {
  .page-header--subline {
    br {
      @include breakpoint($large-up) {
        display: none;
      }
    }
  }
}

#address_form_container {
  .heading--1 {
    margin: 55px 0 24px 0;
  }
  .address_form_container {
    padding: 2rem;
    input[type='text'] {
      width: 60%;
    }
  }
}

.account-page {
  .account-section__content {
    @include breakpoint($medium-up) {
      float: right;
      width: 75%;
      display: block;
    }
    .account-page__section {
      @include breakpoint($medium-up) {
        float: none;
      }
    }
  }
}

.address-book-page__overlay {
  .page-header {
    margin-top: -40px;
  }
  .address-overlay {
    padding: 0rem;
    @include breakpoint($medium-up) {
      padding: 1rem 4rem;
    }
    .address-overlay__title {
      margin-bottom: 60px;
    }
    #address_billing,
    #address_shipping {
      width: 100%;
      input {
        width: 100%;
      }
      .form-item {
        width: 100%;
        padding-right: 0;
        &.name {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 49%;
          }
          display: inline-block;
          &.second {
            margin-left: 0rem;
            @include breakpoint($medium-up) {
              margin-left: 0.65rem;
            }
          }
        }
        &.title,
        &.qas-submit-container {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 49%;
          }
          input {
            background-color: auto;
          }
        }
        .qas_submit_container {
          width: auto;
        }
        &.title {
          width: 100%;
          .selectBox {
            width: 100%;
            @include breakpoint($medium-up) {
              width: 49%;
            }
            padding-right: 13px;
          }
        }
      }
      .address-overlay_submit {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 49%;
        }
      }
    }
  }
}

.account-section {
  &.profile-page {
    .form-submit {
      float: none;
    }
  }
}

.site-footer {
  clear: both;
}

.form-item--reset-password {
  text-align: center;
}

.account-section--nav {
  margin: 2em 0 0;
}

.password-reset-page {
  .password-reset__fieldset {
    display: block;
    padding-top: 10px;
  }
}
