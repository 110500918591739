/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
.bt-overlay-bg {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: black;
  opacity: 0.9;
}

#bt_notification div.bt-content {
  position: relative;
  padding: 15px;
  background-color: black;
  z-index: 1000;
  font-size: 12px;
  line-height: 16px;
  color: white;
  .bt-pp-section {
    background: #930808;
    padding: 10px;
    border: 1px solid #000000;
  }
  .bt-cookie-section {
    padding: 10px;
  }
}
/* CUSTOM POSITIONING */
#bt_notification div.bt-bottom-right {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 24.5em;
  border: 1px solid white;
}

.bt-inner-content {
  margin-top: 10px;
  margin-bottom: 10px;
  h4 {
    font-size: 1.8rem;
  }
  h6 {
    margin-top: 0;
  }
}

#bt_notification .bt_contentarea {
  margin-top: 10px;
}

#bt_notification div.bt-bottom-full {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
/* DEVICES */
#bt_notification div.bt-content-mobile {
  padding: 15px;
  border: 1px solid white;
}
/* BRAND SPECIFICS @JM */
#bt_notification div.bt-content {
  padding: 10px;
}

#bt_notification {
  div.bt-content {
    a {
      color: #fff;
      font-weight: bold;
      border: 0;
    }
    .btn-wrap {
      margin-top: 10px;
      border: 1px solid #fff;
      height: 50px;
      text-align: center;
      a {
        width: 100%;
        font-weight: normal;
        padding: 16px 3px 0px 3px;
        display: block;
        cursor: pointer;
      }
    }
  }
  div.no-pad {
    padding: 0px;
  }
}

.bt-close-link {
  .icon--remove:before {
    color: white;
  }
}

.overlay-close-container {
  position: absolute;
  top: 8px;
  right: 10px;
}

#bt_notification div.bt-content a.bt-close-link {
  display: block;
  width: 15px;
  height: 15px;
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */

/* DEFAULT CONTAINER STYLE */
#bt_settings div.bt-content {
  border: 1px solid black;
  padding: 15px;
  background-color: white;
  z-index: 1001;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 1.5em;
}

#bt_settings div.bt-content div.bt-intro {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro div.bt-intro-copy p {
  margin-top: 1em;
}

#bt_settings {
  display: block !important;
}
/* TOGGLE SLIDER */
#bt_settings div.bt-content div.slide,
#bt_settings div.bt-content ul.slide-toggle {
  position: relative;
  overflow: hidden;
  width: 525px;
}

#bt_settings div.bt-content div.slide {
  margin: 20px 0;
  display: block !important;
}

#bt_settings div.bt-content ul.slide-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent url('/media/images/cookies/all-off.png') 50% 100% no-repeat;
  background-size: contain;
}

#bt_settings div.bt-content div.mask {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 0px;
  width: 0px;
  border-right: 1px solid #eee;
  display: block !important;
}

#bt_settings div.bt-content div.mask ul.slide-toggle {
  background-image: url('/media/images/cookies/all-on.png');
}

#bt_settings div.bt-content div.mask div.drag {
  position: absolute;
  bottom: 12px;
  right: 70px;
  width: 26px;
  height: 11px;
  background: transparent url('/media/images/cookies/icon_arrow.png') 50% 100% no-repeat;
}

#bt_settings div.bt-content ul.slide-toggle li {
  cursor: pointer;
  float: left;
  width: 175px;
  height: 138px;
}

#bt_settings div.bt-content ul.slide-toggle li.middle {
}

#bt_settings div.bt-content ul.slide-toggle li.selected {
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: block;
  font-weight: bold;
  text-align: center;
  margin: 5px;
}
/* PRIVACY INFORMATION */
#bt_settings div.bt-content .bt-privacy-info {
  display: none;
  overflow: hidden;
}

#bt_settings div.bt-content .bt-privacy-info.selected {
  display: block !important;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  padding: 1em 1em 1em 40px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will h3 {
  background-image: url('/media/images/cookies/icon_safe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot h3 {
  background-image: url('/media/images/cookies/icon_unsafe_lock.png');
}

#bt_settings div.bt-content .bt-privacy-info div {
  width: 48%;
  margin-right: 2%;
  float: left;
  display: block !important;
}

#bt_settings div.bt-content .bt-privacy-info div ul {
  list-style-type: none;
  padding-left: 0;
  margin: 1em;
}

#bt_settings div.bt-content .bt-privacy-info div ul li {
  margin: 0.8em 0;
  padding-left: 30px;
  background-position: 0 50%;
  background-repeat: no-repeat;
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-will ul li {
  background-image: url('/media/images/cookies/icon_safe_thick.png');
}

#bt_settings div.bt-content .bt-privacy-info div.bt-privacy-willnot ul li {
  background-image: url('/media/images/cookies/icon_unsafe_thick.png');
}
/* CUSTOM POSITIONING */

/* DEVICES */
#bt_settings div.bt-content-mobile ul.slide-toggle {
  background-image: url('/media/images/cookies/m_all-off.png');
}

#bt_settings div.bt-content-mobile div.mask ul.slide-toggle {
  background-image: url('/media/images/cookies/m_all-on.png');
}

#bt_settings div.bt-content-mobile div.slide {
  margin: 20px auto;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 288px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 96px;
  height: 159px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 36px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li span {
}

#bt_settings div.bt-content-mobile .bt-privacy-info div {
  width: 100%;
  float: none;
}
/* BRAND SPECIFICS */
#bt_settings div.bt-content {
  border-color: #000;
  border-width: 2px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

#bt_settings div.bt-content-desktop {
  padding: 2em;
  height: 100%;
  display: block !important;
}

#bt_settings div.bt-content-mobile {
  display: block !important;
}

#bt_settings div.bt-content div.bt-intro h2 {
  padding: 0;
  margin: 0 0 1em 0;
  border: none;
  text-transform: none;
  color: #000;
}

#bt_settings div.bt-content ul.slide-toggle li span {
  display: none;
}

#bt_settings div.bt-content .bt-privacy-info h3 {
  background-color: #f5f5f5;
}

#bt_settings div.bt-content-mobile div.slide,
#bt_settings div.bt-content-mobile ul.slide-toggle {
  width: 246px;
}

#bt_settings div.bt-content-mobile ul.slide-toggle li {
  width: 82px;
  height: 136px;
}

#bt_settings div.bt-content-mobile div.mask div.drag {
  right: 30px;
  bottom: 11px;
}

button.tealiumMo2TriggerButton {
  display: none !important;
}

.customer-service-section {
  #bt_settings {
    div {
      display: inline;
    }
  }
}

.tealium {
  display: none !important;
}

.device-mobile {
  #bt_settings div.bt-content div.slide {
    margin: 20px auto;
    width: 246px;
  }
  #bt_settings div.bt-content ul.slide-toggle {
    width: 246px;
    padding-left: 0px !important;
    background-image: url('/media/images/cookies/m_all-off.png');
  }
  #bt_settings div.bt-content ul.slide-toggle li {
    width: 82px;
    height: 136px;
  }
  #bt_settings div.bt-content .bt-privacy-info div {
    width: 100%;
    float: none;
  }
  #bt_settings div.bt-content div.mask ul.slide-toggle {
    background-image: url('/media/images/cookies/m_all-on.png');
  }
  #bt_settings div.bt-content div.mask div.drag {
    right: 30px;
    bottom: 11px;
  }
}

#bt_settings div.bt-content .bt-privacy-info.selected[data-userprivacy='OPTED_IN'] div.bt-privacy-willnot {
  display: none !important;
}
