@include breakpoint(($medium-down, $large-only)) {
  .notify-me-overlay-cbox {
    height: 300px !important;
    #cboxWrapper {
      height: 300px !important;
    }
    #cboxLoadedContent {
      height: 300px !important;
    }
  }
}

@include breakpoint($small-down) {
  .notify-me-overlay-cbox {
    .waitlist-form {
      margin-top: 40px;
    }
  }
}
