/** Checkout Styles **/

body.checkout--index {
  .site-header__section--nav {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

body.checkout--viewcart {
  .checkout-panel-main {
    margin: 0 auto 3em;
  }
}

section.panel {
  &.loading {
    & > * {
      opacity: 0.25;
    }
    * {
      cursor: not-allowed;
    }
    position: relative;
    &:before {
      position: absolute;
      content: 'Loading...';
      display: block;
      height: 0;
      top: 50%;
      left: 50%;
    }
  }
  .form-item__group {
    padding-bottom: 2em;
  }
  header {
    a {
      text-decoration: none;
      &.link {
        text-decoration: underline;
      }
    }
  }
}

.checkout-panel-main {
  .panel--alt {
    background-color: $color-lighter-gray;
    padding: 3em 0 0;
  }
  header .h2 span {
    font-weight: lighter;
  }
  section.panel {
    padding: 0 1em;
    .block--fields-are-required {
      float: left;
      width: 100%;
    }
  }
  .cart-header,
  .cart-item--column,
  .product-thumb,
  .product-info {
    float: left;
    font-weight: normal;
  }
  .cart-items {
    width: 100%;
    .total {
      text-align: right;
    }
  }
  .subtotal,
  .shipping,
  .discount,
  .total {
    display: inline-block;
    text-align: right;
  }
  .divide {
    @include breakpoint($medium-up) {
      padding: 1.875rem 0; /* 30px 0 */
    }
  }
  .order-summary-panel {
    &--content {
      max-width: 23.75rem;
    }
  }
  .select-address {
    .selectBox {
      width: 100%;
    }
  }
  .column--left {
    max-width: 51.25rem; /* 820px */
    margin-top: 3em;
    @include breakpoint($medium-down) {
      margin-top: 0;
    }
    section {
      margin-bottom: 1.875rem; /* 30px */
      .messages {
        border-bottom: none;
        display: block;
        padding-left: 0;
        .single-message {
          .close_link {
            display: none;
          }
        }
      }
    }
    .qty-text {
      display: none;
    }
    .cart-items {
      .product {
        width: 58%;
      }
      .price {
        width: 19%;
      }
      .qty {
        width: 7%;
        text-align: center;
        .qty-text {
          text-transform: uppercase;
        }
      }
      .total {
        width: 16%;
      }
      @include breakpoint($medium-down) {
        .cart-header {
          display: none;
        }
        .price,
        .qty {
          float: none;
        }
        .qty {
          margin-top: 0.875rem;
        }
        .price.pc_hidden {
          font-size: 0.59rem;
          display: inline-block;
          padding-left: 0.425rem;
          width: auto;
        }
        .remove.pc_hidden {
          float: left;
        }
        .product {
          width: 100%;
          position: relative;
        }
        .total {
          display: block;
          float: right;
          font-weight: bold;
          margin-top: -18px;
        }
      }
      .cart-item--column.product {
        padding: 0 1.25rem; /* 0 20px */
        .product-thumb {
          padding-right: 1.25rem; /* 20px */
          width: 10.4rem;
          img {
            width: 100%;
          }
          @include breakpoint($medium-down) {
            width: 6.25rem;
            a {
              display: block;
              border: 0;
            }
          }
        }
        .product-info {
          max-width: 12.5rem; /* 200px */
          @include breakpoint($medium-down) {
            .info,
            #cart {
              display: inline-block;
            }
            .info {
              max-width: 10rem;
            }
            .qty {
              width: auto;
              position: absolute;
              right: 1em;
              top: 0;
              margin-top: 0;
            }
          }
        }
      }
    }
    #registration-panel {
      display: none;
    }
  }
  .column--right {
    max-width: 25rem; /* 400px */
    background: $color-lighter-gray;
    margin-top: 3em;
    padding: 0 2em;
    @include breakpoint($medium-up) {
      padding-top: 3em;
    }
    body.checkout--index & {
      @include breakpoint($small-down) {
        padding: 3em 1em 0;
      }
    }
    section.panel {
      margin-bottom: 1.875rem; /* 30px */
      width: 100%;
      padding: 0;
      header {
        margin: 0;
        h2 {
          padding: 0;
          @include h4;
          text-align: left;
          span {
            padding: 0;
            font-weight: inherit;
            &:before,
            &:after {
              content: none;
            }
          }
        }
      }
      .content {
        padding: 0;
      }
      .form-submit {
        position: relative;
        width: 6.25rem; /* 100px */
      }
      .edit-count {
        float: right;
        .item-number,
        .edit {
          float: right;
        }
        .item-number {
          text-transform: uppercase;
        }
        .edit {
          margin-left: 2em;
          text-transform: none;
        }
      }
      .order-summary-panel {
        &--content {
          text-align: right;
          width: 100%;
          padding: 0.625rem 1.25rem 0.625rem 0.625rem; /* 10px 20px 10px 10px */
          @include breakpoint($small-down) {
            margin: 0;
            padding: 0;
            border: 0;
          }
          &-div {
            padding: 0.625rem 0; /* 10px 0 */
            &total {
              padding-top: 1.5625rem; /* 25px */
            }
          }
        }
      }
    } /* .column--right section.panel */
    .panel {
      overflow: hidden;
    }
    .cart-header,
    .cart-item--column.price {
      display: none;
    }
    .divide {
      header {
        display: none;
      }
    }
    .item-count {
      display: inline-block;
    }
    .heading--6-input {
      padding-top: 0.625rem; /* 10px */
    }
    #viewcart-panel {
      border-bottom: 0;
      margin-bottom: 0;
      @include breakpoint($small-down) {
        border: $border-grey;
        border-width: 1px 0;
        margin: 0;
        .cart-item.products {
          display: none;
        }
        header h2 {
          padding: 1.25em 0;
          margin: 0;
          line-height: 1em;
          font-weight: lighter;
          .item-number {
            text-transform: uppercase;
          }
        }
      }
    }
    #order-summary-panel {
      border-top: 0;
      @include breakpoint($small-down) {
        max-width: none;
        padding: 0;
        margin: 0;
        border: 0;
        width: auto;
      }
    }
    #shipmethod-panel {
      .ship-method-group-label {
        a.link.more-info {
          border: none;
        }
        label {
          display: block;
        }
      }
    }
    #links-panel {
      display: block;
      .links_desc {
        display: none;
      }
    }
    .cart-item {
      margin: 0.625rem 1.25rem 0.625rem 0.625rem; /* 10px 20px 10px 10px */
    }
    .selectBox-dropdown {
      font-size: 0.75rem;
    }
    .cart-items {
      position: relative;
      .product {
        width: 65%;
      }
      .qty {
        width: 16%;
        font-size: 90%;
      }
      .total {
        width: 19%;
        text-align: right;
        font-weight: bold;
        font-size: 90%;
      }
    }
    .product-thumb {
      width: 5.715em;
      img {
        width: 100%;
      }
      @include breakpoint($medium-down) {
        width: 6.25rem;
        a {
          display: block;
        }
      }
    }
    .product-info {
      max-width: 5.313rem;
      line-height: 1.15em;
      font-size: 90%;
      margin-top: 0.5em;
      margin-left: 1em;
      @include breakpoint($medium-down) {
        max-width: 6.43rem; /* 200px */
        .info,
        #cart {
          display: inline-block;
        }
        .info {
          max-width: 10rem;
        }
      }
      .product_name {
        font-weight: bold;
        margin-bottom: 1em;
      }
      .size__label {
        display: none;
      }
    }
    .button {
      &:hover {
        color: $color-white;
        background-color: $color-black;
        border: 1px solid $color-black;
      }
    }
    .content {
      .label {
        clear: both;
        width: 15.625rem; /* 250px */
      }
      .value {
        width: 5.625rem; /* 90px */
      }
      .subtotal,
      .shipping,
      .total {
        display: inline-block;
        text-align: right;
        &.label {
          width: 16.625rem;
        }
      }
    }
    .subtotal,
    .shipping,
    .total {
      &.label {
        width: 16.625rem;
      }
    }
  } /* .column-right */

  .cart-header {
    width: 100%;
    height: 2.125rem; /* 34px */
    border-bottom: 1px solid $color-gray;
  }
  .cart-item {
    clear: both;
    padding: 2.5rem 0;
    @include breakpoint($medium-down) {
      padding: 2rem 0;
    }
    &:last-of-type {
      border: none;
    }
  }
  .btn-checkout {
    margin-left: 0.625rem; /* 10px */
    overflow: hidden;
    @include breakpoint($medium-down) {
      margin: 0;
    }
  }
  #checkout_shipping_panel,
  #review-panel {
    .select-address {
      width: 100%;
      .form-item {
        @include breakpoint($medium-down) {
          width: 80%;
          display: block;
          margin: 0.5rem auto 0.5rem;
          #continue-btn {
            margin: 0 auto;
          }
        }
        @include breakpoint($small-down) {
          width: 100%;
        }
      }
    }
    .form-item {
      width: 48%;
      display: inline-block;
      margin: 0.5rem 0.375rem 0.5rem 0; /* 8px 6px 8px 0 */
      @include breakpoint($medium-down) {
        width: 80%;
        margin: 0.5rem auto 0.5rem;
      }
      @include breakpoint($small-down) {
        width: 100%;
      }
      input,
      select {
        width: 100%;
      }
      &.title {
        display: block;
      }
      &.default-shipping {
        vertical-align: middle;
      }
    }
    .adyen-form-item {
      width: 100%;
    }
    .form-item.card-message {
      width: 97.3%;
    }
  }
  .gift-options {
    .wrapItem {
      float: left;
      width: 24%;
    }
    section {
      .checkbox {
        padding-bottom: 20px;
        width: 100%;
        padding-right: 0;
        float: none;
        @include breakpoint($medium-up) {
          padding-bottom: 25px;
          float: left;
          width: 50%;
          padding-right: 13px;
        }
      }
    }
  }
  #shipping-panel {
    header {
      h2 a {
        border: 0;
        float: right;
        @include breakpoint($small-down) {
          margin-top: 15px;
        }
      }
      .h3 span:after {
        margin-right: 30px;
        max-width: -webkit-fill-available;
      }
    }
    &.finished {
      @include breakpoint($small-down) {
        .panel-group,
        .content {
          padding: 0;
        }
      }
    }
  }
  #shipping-panel,
  #registration-panel {
    header {
      position: relative;
    }
  }
  #shipping-panel,
  #review-panel {
    .change-address {
      text-transform: none;
    }
  }
  #checkout_payment {
    .form-item {
      display: block;
    }
    .card_type_field {
      width: 100%;
    }
  }
  #checkout_billing_panel {
    label {
      line-height: 1;
      &:before {
        // vertical-align: top;
      }
    }
    .label-content {
      display: none;
    }
    .payment_type {
      display: inline-block;
      margin-right: 1.25rem; /* 20px */
    }
  }
  .sub_line {
    padding-top: 0.1875rem;
  }
  #order-summary-panel,
  .viewcart-buttons-panel,
  #shipmethod-panel,
  #recommended-products-panel {
    max-width: 51.25rem;
  }
  .viewcart-buttons-panel {
    .button--paypal {
      background: $color-white;
      border: 1px solid $color-light-gray;
      height: 4.1em;
      padding: 0.8em;
    }
    .adyen-viewcart {
      .paypal-checkout,
      .paypal-smart-button {
        width: 100%;
        .zoid-outlet {
          min-width: 100% !important;
          @include breakpoint($medium-up) {
            min-width: 250px !important;
          }
        }
      }
    }
    @include breakpoint($large-up) {
      text-align: right;
      .continue-buttons {
        margin-left: auto;
        width: 70%;
        .button--paypal {
          float: left;
        }
        .paypal-smart-button {
          float: #{$ldirection};
          margin: 4px 0;
        }
        .button--disabled {
          img {
            opacity: 0.2;
          }
        }
      }
      .disabled.continue-checkout {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      }
      .disabled.paypal-checkout {
        opacity: 0.5;
      }
    }
    @include breakpoint($medium-down) {
      .disabled.continue-checkout {
        opacity: 0.5;
      }
      .disabled.paypal-checkout {
        opacity: 0.5;
      }
    }
  }
  #order-summary-panel {
    .subtotal,
    .shipping,
    .discount,
    .total {
      width: 25%;
      padding: 0.5rem 0;
      float: left;
      &.label {
        width: 14.625rem;
        width: 75%;
      }
      &.total {
        padding: 2rem 0 1rem;
        font-size: 110%;
        text-transform: uppercase;
      }
    }
  }
  #links-panel {
    .content {
      font-family: 'HelveticaNeueLTPro-Lt', $font--sans;
      font-size: 0.675rem;
      a {
        @include tracking(50);
        display: inline-block;
        margin-bottom: 1.5em;
        text-transform: uppercase;
        color: $color-dark-gray;
        border: 0;
      }
      .links_desc a {
        display: inline;
      }
    }
  }
  #offer-code-panel {
    .offer-code__input-wrapper {
      margin-bottom: 1em;
    }
    input[type='text'] {
      width: 100%;
    }
    #offer_code {
      .form-submit {
        max-width: none;
        width: 100%;
      }
    }
  }
  @include breakpoint($medium-down) {
    .js-login-link {
      display: block;
      padding-top: 20px;
      text-align: center;
    }
    .form-item.submit {
      width: 100%;
      .form-submit {
        max-width: 100%;
      }
    }
    fieldset .form-item {
      padding-right: 0;
    }
    #recommended-products-panel {
      display: none;
    }
  }
  #shipmethod-panel {
    clear: both;
    #checkout_shipmethod {
      padding: 0;
    }
    .ship-method-group {
      .form-item {
        select {
          padding-right: 25px;
          &.date-list {
            margin-top: 10px;
          }
          @include breakpoint($small-down) {
            font-size: 9px;
            padding-left: 10px;
          }
        }
      }
    }
    .ship-method-collection-group {
      label {
        float: none;
      }
    }
    .selectBox-dropdown {
      width: 100%;
      @include breakpoint($small-down) {
        font-size: 9px;
      }
    }
    .ship-method-group-label {
      & > span {
        display: block;
        padding-bottom: 1em;
      }
      label {
        padding-right: 1.25rem; /* 20px */
        padding-top: 1rem;
      }
    }
    .overlay-wrapper {
      display: inline-block;
    }
    .click-and-collect.more-info {
      margin-left: 0.9375rem; /* 15px */

      span {
        display: inline-block;
      }
    }
    @include breakpoint($medium-down) {
      .content {
        padding: 0;
      }
    }
  }
  .ship-method-group-label {
    label {
      @include breakpoint($medium-down) {
        display: block;
      }
    }
  }
  #review-panel {
    clear: both;
    .heading--container {
      overflow: hidden;
      @include breakpoint($medium-up) {
        .h5--select-payment-type {
          float: left;
        }
      }
    }
    .block--payment-type {
      > span {
        float: left;
        width: 50%;
        display: block;
      }
    }
    .content {
      padding: 3em 1em 1em;
      @include breakpoint($small-down) {
        padding: 0;
      }
    }
    .adyen-content {
      padding: 0;
    }
    .select-address {
      padding-top: 1em;
    }
    .payment-address,
    .heading--payment--terms-and-conditions {
      padding-bottom: 1em;
    }
    #checkout_billing_panel {
      label,
      .payment_type {
        vertical-align: top;
        line-height: 1.8em;
      }
    }
    .sub-section.address {
      @include breakpoint($small-down) {
        margin-top: 2em;
      }
    }
    .block--payment-type {
      overflow: auto;
    }
  }
  #review-panel .form-item.card-expiry-date {
    .selectBox-label {
      width: 8.125rem;
    }
    select,
    .selectBox-dropdown {
      max-width: 47%;
      width: 47%;
      margin-left: 1px;
    }
    .selectBox--expiration-year {
      float: right;
    }
  }
  .remove_link,
  .size {
    margin-top: 1em;
    @include breakpoint($medium-down) {
      margin-top: 0.75rem;
      display: inline-block;
    }
  }
  .remove_link {
    margin-left: 15px;
    &:before {
      content: '\00D7';
      margin-left: -15px;
      margin-right: 7px;
      line-height: 0;
      font-size: 15px;
    }
  }
  @include breakpoint($medium-down) {
    .size {
      font-size: 0.6rem;
    }
    .remove_link {
      position: absolute;
      bottom: -3em;
      left: 7.5rem;
    }
    .qty-text {
      display: none;
    }
  }
  #checkout_review {
    margin-top: 2rem;
    .form-item {
      width: 100%;
      &.msg-order-email {
        display: none;
      }
    }
  }
  #recommended-products-panel {
    .formatted_price,
    .price,
    .quickshop {
      display: none;
    }
  }
  #need-help-panel {
    display: none;
  }
  #confirmation-page {
    text-align: center;
    #guarantee-panel,
    #need-help-panel,
    #links-panel {
      display: none;
    }
  }
  #confirmation-panel {
    padding-top: 5em;
    text-align: center;
    .print-buttons {
      @include breakpoint($medium-down) {
        display: none;
      }
    }
  }
  #sign-in-confirmation-panel {
    max-width: 468px;
    margin: 0 auto;
    width: 100%;
    text-align: left;
  }
  .forgot-pw-note-wrap {
    margin-bottom: 1rem;
    margin-top: 0.5em;
  }
  #checkout_samples {
    .continue-buttons {
      margin-top: 2rem;
    }
  }
  .sign-in-panel {
    overflow: hidden;
    &.paypal.finished {
      display: none;
    }
    .column {
      width: 48%;
      float: left;
      &:nth-child(even) {
        float: right;
      }
      @include breakpoint($medium-down) {
        width: 100%;
        float: none;
      }
    }
    input {
      width: 100%;
    }
    #new-or-returning-radios {
      margin-bottom: 2em;
      .new-customer {
        margin-bottom: 1em;
      }
    }
    #new-account,
    #return-user {
      padding-top: 2em;
      header {
        padding-bottom: 1em;
      }
      .form-item {
        margin-bottom: 1em;
        padding-right: 0px;
        @include breakpoint($landscape-up) {
          padding-right: 20px;
        }
      }
      p {
        display: none;
      }
      .form-submit {
        margin: 2em 0;
      }
    }
  }
  .error_messages {
    padding-top: 20px;
  }
  @include breakpoint($medium-down) {
    .cart-item {
      position: relative;
    }
  }
  .signin-to-see-cart {
    padding: 2rem;
  }
}

.checkout-panel-main,
.sign-in-page,
.samples-page {
  .selectBox-dropdown {
    min-width: 6rem;
    @include breakpoint($medium-down) {
      min-width: 75px;
    }
  }
}
/* Order Confirmation Registration - show error messages */
#confirmation-page {
  .messages {
    display: block;
    .close_link {
      display: none;
    }
  }
  input[type='password'].checkedEmpty {
    border-color: #cd3030;
  }
  input[type='checkbox'].checkedEmpty ~ label,
  input[type='checkbox'].checkedEmpty ~ label a {
    color: #cd3030;
  }
}

#shipping-address-display,
#gift-options-display {
  width: 50%;
  float: left;
}

#delivery-address-h,
#gift-options-h,
#gift-message-h {
  padding-bottom: 1em;
}

#gift-message-h {
  padding-top: 1em;
}

.form-item__label--uppercase {
  text-transform: uppercase;
}

@include breakpoint($medium-down) {
  select {
    max-width: 100%;
  }
}
/* Promo Banners Specific styles */
section#promo-panel {
  margin: 0;
  padding: 0;
  .content {
    margin: 0;
    padding: 0;
  }
}
/* Promo Banners Specific styles */

/* Samples Page Specific styles */
.samples-page {
  @include breakpoint($medium-down) {
    .checkout_samples {
      text-align: center;
    }
  }
  .remaining {
    height: 45px;
  }
}
/* Samples Page Specific styles */

#checkout-review-panel,
#review-panel {
  .adpl {
    .checkbox {
      input[type='checkbox']:not(:checked) ~ label:before {
        content: '';
      }
    }
  }
}

.adyen-checkout-enabled {
  .view-address {
    position: relative;
    .change-address {
      background: #{$color-white};
      padding-#{$ldirection}: 10px;
      position: absolute;
      #{$rdirection}: 0;
      top: 0;
    }
  }
  .shipping-address-part {
    min-width: 100%;
  }
  .gift-options-part {
    min-width: 100%;
    .panel {
      &-left {
        float: #{$ldirection};
        padding: 0;
        width: 40%;
        .gift-options-title {
          padding-top: 1em;
        }
      }
      &-right {
        float: #{$rdirection};
        padding: 0;
        width: 60%;
      }
    }
  }
  .checkout_payment_form {
    .payment-option {
      border-bottom: 1px solid #{$color-dark-gray};
      min-height: 50px;
      @include breakpoint($medium-up) {
        min-width: 430px;
      }
      .payment_options-terms,
      .adyen-payment {
        display: none;
        margin-#{$ldirection}: 27px;
      }
      .paypal-terms {
        .local-submits {
          display: block;
          .paypal-express-btn {
            background: #{$color-black};
            color: #{$color-white};
            padding: 5px 40px;
          }
        }
      }
      .related-media {
        display: inline-block;
        padding: 15px;
        vertical-align: middle;
        &.credit-card {
          padding: 5px;
          .card-img {
            margin-#{$ldirection}: 4px;
            width: 34px;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 6px;
              width: 40px;
            }
          }
        }
        &.paypal-card {
          @include breakpoint($medium-up) {
            padding: 9px;
          }
          .paypal-image {
            margin-#{$ldirection}: 2px;
            width: 59px;
            height: 20px;
            @include breakpoint($medium-up) {
              width: 74px;
              margin-#{$ldirection}: 0;
            }
          }
        }
      }
      input[type='radio'] {
        &:checked {
          & ~ label {
            .adyen-payment,
            .payment_options-terms {
              display: block;
            }
          }
        }
      }
      .pay-with-text {
        font-size: 18px;
      }
      .adyen-payment-part {
        input[type='checkbox'] {
          & ~ span {
            &::before {
              background: #{$color-white};
              border: 2px solid #{$color-black};
              border-radius: 0;
              content: ' ';
              cursor: pointer;
              display: inline-block;
              height: 16px;
              position: absolute;
              top: 4px;
              width: 16px;
              #{$ldirection}: 0;
            }
          }
          &:checked {
            & ~ span {
              &::after {
                @include swap_direction(border-width, 0 2px 2px 0);
                #{$ldirection}: 6px;
                background: #{$color-white};
                border-color: #{$color-black};
                border-style: solid;
                border-radius: 0;
                content: ' ';
                cursor: pointer;
                display: block;
                height: 8px;
                position: absolute;
                transform: rotate(45deg);
                top: 7px;
                width: 4px;
                -webkit-transform: rotate(45deg);
              }
            }
          }
        }
      }
      .payment-terms {
        width: 80%;
        @include breakpoint($medium-up) {
          width: 54%;
        }
        .overlay-wrapper {
          display: inline-block;
        }
      }
    }
    .local-submits {
      display: none;
    }
  }
  .chckt-sdk {
    .chckt-pm {
      border: none;
      &__header {
        background: #{$color-white};
        padding-#{$ldirection}: 0;
      }
      &__image {
        margin: 0;
        #{$rdirection}: 2px;
      }
      &__name {
        color: #{$color-black};
      }
      &__details {
        background: #{$color-white};
        padding-#{$ldirection}: 0;
        .chckt-input-field--recurring {
          background: #{$color-white};
          border: 1px solid #{$color-dark-gray};
          border-radius: 0;
          height: 40px;
          padding: 12px 10px;
          @include breakpoint($medium-up) {
            height: 45px;
            padding: 8px 10px;
          }
        }
      }
    }
    .chckt-form--max-width {
      color: #{$color-black};
      @include breakpoint($medium-up) {
        min-width: 400px;
      }
      .chckt-form-label__text {
        color: #{$color-black};
        &--dark {
          padding: 0 25px;
        }
      }
    }
    .chckt-form-label {
      &--exp-date {
        width: 50%;
        min-width: 132px;
        @include breakpoint($medium-up) {
          min-width: 182px;
        }
      }
      &--cvc {
        float: #{$ldirection};
        margin-#{$ldirection}: 3%;
        width: 47%;
        @include breakpoint($medium-up) {
          min-width: 178px;
        }
      }
      &__error-text {
        color: #{$color-red};
        text-transform: none;
      }
    }
    .chckt-input-field {
      border-radius: 0;
      border-color: #{$color-dark-gray};
      color: #{$color-black};
      height: 40px;
      @include breakpoint($medium-up) {
        height: 45px;
      }
      &--cvc {
        width: 100%;
        max-width: 220px;
        min-width: 130px;
        @include breakpoint($medium-up) {
          min-width: 176px;
        }
      }
      &--error {
        color: #{$color-red};
        border: 2px solid #{$color-red};
      }
    }
    .chckt-button-container {
      width: 98%;
      @include breakpoint($medium-up) {
        min-width: 400px;
        width: 51%;
      }
      .chckt-more-pm-button {
        background: #{$color-white};
        border: none;
        padding-#{$ldirection}: 0;
        &__icon {
          display: none;
        }
        &__text {
          color: #{$color-black};
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-#{$ldirection}: 0;
          text-decoration: underline;
        }
      }
      .chckt-button {
        background: #{$color-black};
        margin-top: 0;
        padding: 10px;
        &.chckt-button--disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .checkout__content {
    .checkout__subtitle {
      font-size: 15px;
      font-weight: normal;
      letter-spacing: 0;
    }
  }
  .msg-panel {
    background: #{$color-black};
    color: #{$color-white};
    line-height: 2;
    margin-top: 15px;
    padding: 10px 10px 0;
    .panel-text {
      color: #{$color-white};
      line-height: 1.5;
    }
  }
}

.paypal-loading {
  background-size: 23px;
  background-position: top;
  display: inline-block;
  height: 30px;
  margin-#{$rdirection}: 10px;
  width: 30px;
}

.paypal-process {
  opacity: 0.2;
}

.paypal-overlay {
  background: #{$color-black};
  #{$ldirection}: 0;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.paypal-processing {
  #{$ldirection}: 0;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 25%;
  width: 100%;
  z-index: 999;
  .process-content {
    background: #{$color-white};
    max-width: 335px;
    margin: 0 auto;
    position: relative;
    padding: 30px 10px;
    z-index: 999;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .kl-logo {
    padding-bottom: 25px;
  }
}
