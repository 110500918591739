.cs-contact-form {
  &__input--half {
    float: none;
    margin-top: 5px;
    .cs-contact-form {
      &__radio {
        width: auto;
      }
    }
  }
}
