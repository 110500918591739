/* General styles (checkout and account) */
.site-content {
  body.account--signin & {
    @include breakpoint($medium-up) {
      background: #161616;
    }
  }
  overflow: hidden;
}

.pg_wrapper {
  ul.error_messages {
    color: $color-error;
    li:not([style*='none']) {
      margin-top: 1em;
    }
    li:not([style*='none']) ~ li:not([style*='none']) {
      margin-top: 0;
    }
  }
}

.messages,
.item-count,
#mobile-breadcrumb {
  display: none;
}

.messages {
  background-color: $color-white;
  color: $color-red;
  border-bottom: 1px solid $color-gray;
}

.page-header {
  background-color: $color-lighter-gray;
  margin: 0 auto;
  text-align: center;
  padding: 3em 0;
  @include breakpoint($medium-up) {
    &.page-header--full-width {
      width: 100%;
      text-align: center;
      position: relative;
      &:before,
      &:after {
        content: '';
        background-color: $color-lighter-gray;
        display: block;
        height: 100%;
        width: 100%;
        right: 100%;
        top: 0;
        position: absolute;
      }
      &:after {
        left: 100%;
      }
    }
  }
  .page-header--subline {
    font-size: 90%;
    line-height: 1.75em;
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

header.lines {
  width: 100%;
  border-top: 1px solid $color-gray;
  border-bottom: 1px solid $color-gray;
  padding: 0.9375rem; /* 15px */
  h2 {
    margin: 0;
  }
}

.checkout {
  overflow: visible;
}

.checkout-panel-main,
.account-section,
.samples-page {
  width: 100%;
  overflow: visible;
  max-width: $max-width;
  margin: 0 auto 2.5rem;
  .panel-group,
  .content {
    padding: 0.625rem 0.625rem 1.25rem; /* 10px 10px 20px */
  }
  .column {
    width: 100%;
  }
  .button {
    width: 100%;
    @include breakpoint($medium-up) {
      max-width: 16rem;
    }
  }
  .viewcart-buttons-panel {
    .continue-buttons {
      a {
        display: inline-block;
      }
      @include breakpoint($large-down) {
        .btn-checkout,
        .go-shopping {
          max-width: 100%;
        }
      }
    }
  }
  @include breakpoint($large-down) {
    .continue-buttons,
    #continue-btn {
      text-align: center;
    }
    .panel {
      clear: both;
      &.base {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        .heading--5 {
          border-bottom: none;
        }
        .content {
          padding: 0.625rem 0 1.25rem;
        }
      }
    }
  }
  @include breakpoint($medium-down) {
    section.panel.panel--alt {
      padding: 3em 1em 0;
    }
  }
}

.samples-page {
  .product-list {
    li.product {
      list-style: none;
      width: 24.5%;
      padding: 0 10px 0;
      margin: 0 0 20px;
      display: inline-block;
      text-align: center;
      vertical-align: bottom;
      a.sample-select-button {
        width: auto;
        margin: 15px 0 0;
      }
      input[type='checkbox'] ~ label,
      input[type='checkbox'] ~ .label {
        text-align: left;
      }
    }
    @include breakpoint($large-down) {
      li.product {
        width: 100%;
      }
      .product-name,
      .product-subhead,
      .product-size {
        font-size: 11px;
      }
    }
  }
}

.tabs__wrapper {
  max-width: 580px;
  margin: 0 auto;
  background: $color-white;
  .tabs {
    overflow: hidden;
    .tabs__tab {
      @include h4;
      display: block;
      background: $color-light-gray;
      border: 0;
      width: 50%;
      float: left;
      padding: 2em 0;
      font-size: 100%;
      text-align: center;
      cursor: pointer;
      &.tabs__tab--active {
        background: $color-white;
      }
    }
  }
  .tabs__content {
    padding: 0 1em;
    @include breakpoint($medium-up) {
      padding: 1em 7em 4em;
    }
  }
  .tabs__content__header {
    padding: 3em 0;
  }
}

.page-errors--signin {
  .error_messages li {
    margin: 1em 0;
  }
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: auto;
  a {
    color: #fff;
    text-decoration: underline;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
}

.newsletter-info__interest--user {
  .newsletter-info__interest {
    display: grid;
  }
}

#shipping-panel {
  .tooltip {
    margin: 10px 0px 20px 0px;
  }
}
/* Tooltip does not work as footer has overflow hidden */
.site-footer {
  .site-email-signup {
    overflow: visible;
  }
}
